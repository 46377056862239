<template>
  <div>
    <v-carousel
      cycle
      height="400"
      hide-delimiter-background
      :show-arrows="false"
      hide-delimiters
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn color="success" v-bind="attrs" v-on="on">Previous slide </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn color="info" v-bind="attrs" v-on="on">Next slide </v-btn>
      </template>

      <v-carousel-item src="../assets/banner001.jpg">
        <v-sheet color="rgb(14,127,159,0.9)" height="100%">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
            style="
              margin-left: 10px;
              margin-right: 10px;
              background-color: transparent;
            "
          >
            <div
              :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4'"
              style="
                font-family: 'fantasy';
                font-weight: bold;
                text-align: center;
                line-height: 50px;
              "
            >
              <h1>
                {{ countryName.toUpperCase() }}
                <span style="color: yellow">Real Estate</span> News
              </h1>
              <v-row>
                <v-col align-self="center" md="6" offset-md="3">
                  <v-text-field
                    style="
                      opacity: 0.9;
                      margin-top: 50px;
                      border-width: 3px !important;
                    "
                    :placeholder="$t('searchKeyword')"
                    rounded
                    outlined
                    clearable
                    v-model="params.search"
                    append-icon="mdi-magnify"
                    @click.stop="noFn()"
                    @click:append.stop="handleSearch()"
                    v-shortkey="['enter']"
                    @shortkey.native="handleSearch()"
                    @click:clear="clearSearch()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-container>
      <v-row align="center" justify="center">
        <v-col>
          <h2 style="color: #6c2c73" class="text-center mb-10">
            {{ $t("latestNews") }}
          </h2>
          <v-row>
            <v-col cols="12" lg="6" v-for="n in fetchedNews" :key="n._id">
              <v-hover v-slot="{ hover }" close-delay="200">
                <v-card
                  style="cursor: pointer"
                  class="mx-auto"
                  :elevation="hover ? 6 : 2"
                  @click="
                    goToDetail('newsDetail', {
                      newsId: n._id,
                      newsTitle: n.title,
                      newsBody: n.body,
                      newsCreatedAt: n.createdAt,
                      newsUrl: n.hasOwnProperty('url') ? n.url : undefined,
                    })
                  "
                >
                  <v-img
                    v-if="n.url === '' || n.url === undefined"
                    :alt="n.title"
                    class="align-end"
                    height="250px"
                    src="../assets/images/no-photo-available.png"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :alt="n.title"
                    class="align-end"
                    height="250px"
                    :src="n.url"
                  >
                  </v-img>

                  <v-card-subtitle class="pb-0">
                    {{ n.createdAt | momentFormatM }}
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <h2>
                      {{
                        n.title.length > 30
                          ? n.title.substring(0, 30) + "..."
                          : n.title
                      }}
                    </h2>

                    <div>{{ n.body.substring(0, 150) + "..." }}</div>
                  </v-card-text>

                  <!-- <v-card-actions>
                    <v-btn
                      color="purple"
                      text
                    >
                      Share
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="purple"
                      text
                    >
                      Explore
                    </v-btn>
                  </v-card-actions> -->
                </v-card>
                <!-- <v-card
                style="box-shadow: none; cursor: pointer"
                class="mb-10"
                :elevation="hover ? 6 : 0"
                @click="
                  goToDetail('newsDetail', {
                    newsId: n._id,
                    newsTitle: n.title,
                    newsBody: n.body,
                    newsCreatedAt: n.createdAt,
                    newsUrl: n.hasOwnProperty('url') ? n.url : undefined,
                  })
                "
              >
                <v-img
                  v-if="n.url === '' || n.url === undefined"
                  :alt="n.title"
                  style="background-size: contain, cover; border-radius: 10px"
                  width="100%"
                  height="300px"
                  src="../assets/images/No_image_available.png"
                  lazy-src="../assets/images/no-image-icon.png"
                />
                <v-img
                  :alt="n.title"
                  v-else
                  :src="n.url"
                  style="background-size: contain, cover; border-radius: 5px"
                  width="100%"
                  height="300px"
                />

                <v-card-subtitle
                  class="pb-0"
                  style="padding: 0; font-size: 12px"
                >
                  {{ n.createdAt | momentFormatM }}
                </v-card-subtitle>

                <v-card-title class="text--primary" style="padding: 0">
                  <h2>{{ n.title.length > 30 ? n.title.substring(0,30)+"..." : n.title }}</h2>
                </v-card-title>
                <v-card-text style="padding: 0">
                  <p>{{ n.body.substring(0, 150) + "..." }}</p>
                </v-card-text>
              </v-card> -->
              </v-hover>
            </v-col>
            <loading-circle v-if="loading"></loading-circle>
            <v-col
              v-if="totalPage > 0"
              cols="12"
              sm="12"
              md="12"
              style="text-align: center"
            >
              <v-pagination
                v-model="page"
                :length="Math.ceil(totalPage / 10)"
                :total-visible="9"
                circle
              ></v-pagination>
            </v-col>
            <v-col v-else cols="12" style="text-align: center">
              <p>Sorry, no news found!</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import MainMixin from "@/mixins/mainMixin";
import _ from "lodash";
import { countyMobileList } from "@/libs/countryMobileWithFlag";
import loadingCircle from "../components/loadingCircle.vue";

export default {
  name: "News",
  mixins: [MainMixin],
  mounted() {
    this.$jQuery("body").off();
  },
  components: { loadingCircle },
  data() {
    return {
      loading: true,
      page: 1,
      totalPage: 0,
      skip: 0,
      limit: 10,
      params: {
        locationId: "",
        type: "",
        search: "",
        minPrice: "",
        maxPrice: "",
        groupType: "sale",
        countryId: "",
      },
    };
  },
  methods: {
    clearSearch() {
      let vm = this;
      vm.params.search = "";
    },
    handleSearch() {
      let vm = this;
      vm.loading = true;
      vm.page = 1;
      vm.queryNews(vm.params.search);
      vm.forceRerender();
    },
    queryNews: _.debounce(function (search, skip, limit, countryCode) {
      let vm = this;
      vm.fetchNews({
        search: search || "",
        skip: skip || 0,
        limit: limit || 10,
        sortBy: vm.sortBy || "",
        sortDesc: vm.sortDesc || "",
        countryCode: countryCode || "",
      });
      vm.loading = false;
    }, 500),
    goToDetail(link, params) {
      let vm = this;
      if (vm.$router.history.current.path !== link) {
        this.$router
          .push({
            name: link,
            params: params,
          })
          .catch(() => {});
      }
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderInfoWindow = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderInfoWindow = true;
      });
    },
    noFn() {},
  },
  watch: {
    page(val) {
      let vm = this;
      vm.loading = true;
      vm.skip = 1 * (val - 1);
      vm.limit = 10;
      vm.countryCode = "";
      vm.queryNews(vm.params.search, vm.skip, vm.limit, vm.countryCode);
    },
    "params.search"(val) {
      let vm = this;
      if (val === "") {
        vm.page = 1;
        vm.loading = true;
        vm.queryNews(vm.params.search, vm.skip, vm.limit, vm.countryId);
      }
    },
  },
  created() {
    let vm = this;
    if (!(localStorage.country !== undefined && localStorage.country !== "")) {
      vm.$jQuery.get(
        "https://www.cloudflare.com/cdn-cgi/trace",
        function (data) {
          if (data) {
            vm.$store.state.country = data.split("loc=")[1].substr(0, 2);
            localStorage.country = vm.$store.state.country;
            let cDoc = countyMobileList.find((o) => {
              return (
                o.value === vm.$store.state.country ||
                o.number === localStorage.country
              );
            });
            vm.params.countryId = cDoc.number;
            localStorage.countryCode = cDoc.value;
            // vm.mapOptions.center = cDoc.position;
            vm.countryName = cDoc.name;
            vm.forceRerender();
          }
        }
      );
    } else {
      let cDoc = countyMobileList.find((o) => {
        return (
          o.value === localStorage.country || o.number === localStorage.country
        );
      });
      vm.params.countryId = cDoc.number;
      localStorage.countryCode = cDoc.value;
      // vm.mapOptions.center = cDoc.position;
      vm.countryName = cDoc.name;
      vm.forceRerender();
    }
    vm.queryNews();
    const top = window.pageYOffset;
    this.currentPoint = top;
    let goPoint = this.currentPoint > 160 ? 135 : 0;
    this.$vuetify.goTo(goPoint);
  },
};
</script>
