var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-carousel',{attrs:{"cycle":"","height":"400","hide-delimiter-background":"","show-arrows":false,"hide-delimiters":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success"}},'v-btn',attrs,false),on),[_vm._v("Previous slide ")])]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info"}},'v-btn',attrs,false),on),[_vm._v("Next slide ")])]}}])},[_c('v-carousel-item',{attrs:{"src":require("../assets/banner001.jpg")}},[_c('v-sheet',{attrs:{"color":"rgb(14,127,159,0.9)","height":"100%"}},[_c('v-row',{staticClass:"fill-height",staticStyle:{"margin-left":"10px","margin-right":"10px","background-color":"transparent"},attrs:{"align":"center","justify":"center"}},[_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4',staticStyle:{"font-family":"'fantasy'","font-weight":"bold","text-align":"center","line-height":"50px"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.countryName.toUpperCase())+" "),_c('span',{staticStyle:{"color":"yellow"}},[_vm._v("Real Estate")]),_vm._v(" News ")]),_c('v-row',[_c('v-col',{attrs:{"align-self":"center","md":"6","offset-md":"3"}},[_c('v-text-field',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['enter']),expression:"['enter']"}],staticStyle:{"opacity":"0.9","margin-top":"50px","border-width":"3px !important"},attrs:{"placeholder":_vm.$t('searchKeyword'),"rounded":"","outlined":"","clearable":"","append-icon":"mdi-magnify"},on:{"click":function($event){$event.stopPropagation();return _vm.noFn()},"click:append":function($event){$event.stopPropagation();return _vm.handleSearch()},"click:clear":function($event){return _vm.clearSearch()}},nativeOn:{"shortkey":function($event){return _vm.handleSearch()}},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}})],1)],1)],1)])],1)],1)],1),_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('h2',{staticClass:"text-center mb-10",staticStyle:{"color":"#6c2c73"}},[_vm._v(" "+_vm._s(_vm.$t("latestNews"))+" ")]),_c('v-row',[_vm._l((_vm.fetchedNews),function(n){return _c('v-col',{key:n._id,attrs:{"cols":"12","lg":"6"}},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",staticStyle:{"cursor":"pointer"},attrs:{"elevation":hover ? 6 : 2},on:{"click":function($event){_vm.goToDetail('newsDetail', {
                    newsId: n._id,
                    newsTitle: n.title,
                    newsBody: n.body,
                    newsCreatedAt: n.createdAt,
                    newsUrl: n.hasOwnProperty('url') ? n.url : undefined,
                  })}}},[(n.url === '' || n.url === undefined)?_c('v-img',{staticClass:"align-end",attrs:{"alt":n.title,"height":"250px","src":require("../assets/images/no-photo-available.png")}}):_c('v-img',{staticClass:"align-end",attrs:{"alt":n.title,"height":"250px","src":n.url}}),_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm._f("momentFormatM")(n.createdAt))+" ")]),_c('v-card-text',{staticClass:"text--primary"},[_c('h2',[_vm._v(" "+_vm._s(n.title.length > 30 ? n.title.substring(0, 30) + "..." : n.title)+" ")]),_c('div',[_vm._v(_vm._s(n.body.substring(0, 150) + "..."))])])],1)]}}],null,true)})],1)}),(_vm.loading)?_c('loading-circle'):_vm._e(),(_vm.totalPage > 0)?_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.totalPage / 10),"total-visible":9,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('p',[_vm._v("Sorry, no news found!")])])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }